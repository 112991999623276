import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

switch (window.location.hostname) {
  case 'localhost':
    window.API_ROOT = 'http://localhost:8000'
    window.CABINET_ROOT = 'http://localhost:8080'
    // // window.API_ROOT = 'https://birgeoqy-api.elumiti.kz'
    break;
  case 'birgeoqy.elumiti.kz':
    window.API_ROOT = 'https://birgeoqy-api.elumiti.kz'
    window.CABINET_ROOT = 'https://birgeoqy.elumiti.kz'
    break;
  case 'birgeoqy.kz':
    window.API_ROOT = 'https://api.birgeoqy.kz'
    window.CABINET_ROOT = 'https://birgeoqy.kz'
    break;
  default:
    window.API_ROOT = 'https://api.birgeoqy.kz'
    window.CABINET_ROOT = 'https://birgeoqy.kz'
}

Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}
Vue.http.options.root = API_ROOT

Vue.http.options.progress = function (event) {
  if (event.lengthComputable) {
    NProgress.set(event.loaded / event.total)
  }
}

Vue.http.interceptors.push((request, next) => {
  NProgress.start()

  if (localStorage.getItem('token')) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
  }
  next((response) => {
    NProgress.done()
    if (!response.ok) {
      Vue.toastr({
        message: 'Error',
        description: 'Server not available',
        type: 'error'
      })
    }
    if (response.status === 401) {
        localStorage.setItem('session_id', '');
        localStorage.setItem('token', '');
        localStorage.setItem('user', '');
    }
    if (response.status >= 400) {
      Vue.toastr({
        message: 'Error',
        description: (response.body.message) ? response.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
        type: 'error'
      })
    }
    return response
  })
})

export const GetEmail = Vue.resource(API_ROOT + '/api/email')

export const GetIINByEmail = Vue.resource(API_ROOT + '/api/iin-by-email')

export const Auth = Vue.resource(API_ROOT + '/api/login')

export const Logout = Vue.resource(API_ROOT + '/api/logout')

export const SendSMS = Vue.resource(API_ROOT + '/api/sms')
