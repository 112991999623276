<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed" @click="collapsed=!collapsed" v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><div class="menu-btn-burger"></div></span>
        </button>
        <a @click.prevent="logoClick" class="navbar-brand">
            <img width="75" :src="'/images/logo.png'" style="" alt="">
        </a>

        <div class="navbar-collapse desktop-menu" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="tel:88000804848"><i class="phone-icon"></i>8 800 080 4848</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      collapsed: true,
    }
  },
  methods: {
    logoClick(){
      return false;
    },
    clickaway () {
      this.collapsed = true
    },
  }
}
</script>
