<template>
  <div id="app" class="app quiz-wrapper" ref="app">
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// import '../../../public/css/quiz/fonts.css';
import '../../../public/css/quiz/main.less';
import '../../../public/css/quiz/profile.less';
import '../../../public/css/quiz/media.css';
import '../../../public/css/quiz/ck-content.css';
export default {
  name: 'AppLayout',
}
</script>
