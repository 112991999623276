<template>
    <div class="Sidebar">
        <div class="sidebar-logo">
            <a href="#" @click.prevent="$router.push({ path: '/' })">
                <img src="/images/logo.png" alt="">
            </a>
        </div>
        <div class="sidebar-lesson-block"
             :class="{'d-none': !scheduleLinkIsExists(), 'd-block': scheduleLinkIsExists()}" target="_blank">
<!--            <div class="sidebar-lesson-block__lesson">-->
<!--                <div class="sidebar-lesson-block__title">-->
<!--                    Русский язык-->
<!--                </div>-->
<!--                <div class="sidebar-lesson-block__time">-->
<!--                    <img src="/images/profile/clock.svg" width="15" height="16" class="mr-1" alt="">-->
<!--                    <div>14:00-14:45</div>-->
<!--                </div>-->
<!--            </div>-->
            <el-button
                :loading="loading"
                @click.prevent="goToScheduleLink"
                :href="scheduleLink"
                class="sidebar-lesson m-2 mx-auto btn-danger">
                    {{ $t('profile.connect-to-lesson') }}
            </el-button>
        </div>
        <div class="sidebar-mini">
            <div @click="showJournalInfoData=false; showTestInfoData=false"  class="sidebar-items">
                <router-link to="/profile-teacher" class="sidebar-item" active-class="active">
                    <img src="/images/profile-main-active.svg" class="sidebar-item__icon" v-if="$route.path === '/profile-teacher'" alt="">
                    <img src="/images/profile-main.svg" class="sidebar-item__icon" v-else alt="">
                    <p>{{ $t('profile.main-item') }}</p>
                </router-link>
                <router-link to="/teacher-schedule" class="sidebar-item" active-class="active">
                    <img src="/images/profile-schedule-2-active.svg" class="sidebar-item__icon" v-if="$route.path === '/teacher-schedule'" alt="">
                    <img src="/images/profile-schedule-2.svg" class="sidebar-item__icon" v-else alt="">
                    <p>{{ $t('profile.scheduleTeacher') }}</p>
                </router-link>
            </div>
            <div class="sidebar-item"
                 :class="{active: $route.path.substring(0, 8) === '/journal'}"
                 @click="showJournalInfoData=!showJournalInfoData; showTestInfoData=false">
                <img
                    v-if="$route.path.substring(0, 8) === '/journal'"
                    src="/images/profile-dnevnik-active.svg" class="sidebar-item__icon" alt="">
                <img v-else src="/images/profile-dnevnik.svg" class="sidebar-item__icon" alt="">
                <p>{{ $t('profile.journal') }}
                    <span
                        v-if="$route.path.substring(0, 8) === '/journal'"
                        class="sidebar-item-submenu-active"
                        v-bind:class="{ 'rotate180': showJournalInfoData }"
                    ></span>
                    <span v-else class="sidebar-item-submenu" v-bind:class="{ 'rotate180': showJournalInfoData }"></span>
                </p>
            </div>
            <div v-show="showJournalInfoData" class="sidebar-sub">
                <router-link tag="div" to="/journal/ktp" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.ktp.title')}}</div>
                </router-link>
                <router-link tag="li" to="/journal/homeworks" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.homework')}}</div>
                </router-link>
                <router-link tag="li" to="/journal/results" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.results')}}</div>
                </router-link>
                <router-link tag="li" to="/journal/performance" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.performance-sub-item')}}</div>
                </router-link>
                <router-link tag="li" to="/journal/attendance" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.attendance-sub-item')}}</div>
                </router-link>
            </div>
            <div class="sidebar-item" :class="{active: $route.path === '/tests' || $route.path === '/bank' }"
                 @click="showTestInfoData = !showTestInfoData; showJournalInfoData=false">
                <img v-if="$route.path === '/tests' || $route.path === '/bank'" src="/images/profile-test-active.svg" class="sidebar-item__icon" alt="">
                <img v-else src="/images/profile-test.svg" class="sidebar-item__icon" alt="">
                <p>{{ $t('profile.tests') }}
                <span v-if="$route.path === '/tests' || $route.path === '/bank'" class="sidebar-item-submenu-active"  v-bind:class="{ 'rotate180': showTestInfoData }"></span>
                <span v-else class="sidebar-item-submenu"  v-bind:class="{ 'rotate180': showTestInfoData }"></span></p>
            </div>
            <div v-show="showTestInfoData" class="sidebar-sub">
                <router-link tag="li" to="/tests" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.testing.test-tab')}}</div>
                </router-link>
                <router-link tag="li" to="/bank" class="sidebar-sub-item" active-class="activeSubItem">
                    <div class="sidebar-sub-item-text">{{$t('profile.testing.bank-question')}}</div>
                </router-link>
            </div>
            <div @click="showJournalInfoData=false; showTestInfoData=false" class="sidebar-items">
                <router-link to="/teacher/settings" class="sidebar-item" active-class="active">
                    <img src="/images/settings-active.png" class="sidebar-item__icon" v-if="$route.path === '/teacher/settings'" alt="">
                    <img src="/images/settings.png" class="sidebar-item__icon" v-else alt="">
                    <p>{{ $t('profile.settings.settings') }}</p>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    computed: {
        ...mapGetters({
            activeMenu: 'getActiveMenu'
        })
    },
    data() {
        return {
            loading: false,
            scheduleLink: null,
            showJournalInfoData: false,
            showTestInfoData: false,
        }
    },
    methods: {
        async goToScheduleLink() {
            this.loading = true
            this.$http.get(window.API_ROOT + '/api/teacher/schedule-link')
                .then((res) => {
                    this.loading = false
                    sessionStorage.setItem('schedule-link', res.body.link)
                    this.scheduleLink = res.body.link
                    if (res.body.link != null) {
                        this.$router.push({ name: 'teacher-conference', params: { room: res.body.link } }).catch(err => {
                            console.log(err)
                        })
                        // window.open(res.body.link, '_blank');
                    } else {
                        Vue.toastr({
                            message: 'Урок не найден',
                            description: '',
                            type: 'danger'
                        })
                    }
              })
              .catch(() => {
                  this.loading = false
              });
        },
        getActualScheduleLink() {
            this.$http.get(window.API_ROOT + '/api/teacher/schedule-link')
                .then((res) => {
                    this.loading = false
                    sessionStorage.setItem('schedule-link', res.body.link)
                    this.scheduleLink = res.body.link
                })
                .catch(() => {
                    this.loading = false
                });
        },
        scheduleLinkIsExists () {
            return !(this.scheduleLink === 'null' || (typeof this.scheduleLink == 'object'));
        },
        ...mapActions({
            setActiveMenu: 'setActiveMenu'
        }),
    },
    mounted() {
        this.getActualScheduleLink()
    }
}
</script>

<style scoped>
.Sidebar {
    background: #FFF!important;

}
.sidebar-items{
    width: 100%;
}
.sidebar-mini{
  width: 204px;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  padding-bottom: 70px;
  font-family: 'Roboto', sans-serif;
    background: #FFF;
}
.bg-white{
  background-color: white
}
.activeSubItem {
  color: #D23168 !important;
  border-left: 4px solid #D23168 !important;
  font-weight: 500!important;
}
.rotate180{
  transform: rotate(180deg)
}

.sidebar-sub-item-text {
  display: block;
  margin-left: 23px;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.sidebar-sub-item {
  cursor: pointer;
  user-select: none;
  display: flex;
  height: 48px;
  border-left: 2px solid #D7CEEE;
  color: #473F95;
}

.sidebar-item-submenu-active {
  /* width: 16px;
  height: 16px; */    
  right: 19px;
  bottom: 16px;
  width: 12px!important;
  height: 8px!important;
  background-size: contain;
  background-repeat: no-repeat;
  /* right: 23px; */
  position: absolute;
  background-image: url("/images/chevron-up-white.svg");
}

.sidebar-item-submenu {
  /* width: 16px;
  height: 16px; */
  background-size: contain;
  background-repeat: no-repeat;
  /* right: 23px; */
  /* position: absolute; */
  background-image: url("/images/chevron-up-black.svg");

    position: absolute;
    right: 19px;
    bottom: 16px;
    width: 12px!important;
    height: 8px!important;
}

.Sidebar {
    width: 204px;
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* border-radius: 0 12px 12px 0; */
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0 4px 16px rgb(0 0 0 / 8%);
    min-height: 100vh;
    /*overflow-y: scroll;*/
    top: 0;
    bottom: 0;
    /* padding-bottom: 70px; */
    font-family: 'Roboto', sans-serif;
    background: #F4F4FC;
    z-index: 2;
}

::-webkit-scrollbar {
  display: none;
}

.sidebar-logo {
  padding: 28px 0 16px 0;
  width: 100%;
  background: #FFF;
  display: flex;
  justify-content: center;
}
.sidebar-logo > a > img {
    width: 85px;
}
.sidebar-item {
    display: flex;
    /* flex-flow: column; */
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 11px 0;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    background-color: #FFF;
    text-decoration: none !important;
    padding-left: 24px;
}

.sidebar-item:hover {
  background: #F4F4FC;
}

.sidebar-item.active {
  background: #473F95;
}

.sidebar-item p {
    font-weight: normal;
    font-size: 14px;
    line-height: 142%;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #473F95;
    margin: 0;
}

.sidebar-item.active p {
  color: #fff;
}
.sidebar-item__icon {
    width: 21px;
    height: 21px;
    margin-right: 16px;
}
.sidebar-sub {
    width: 100%;
    padding-left: 31px;
    background-color: #F7F4FF;
}
.sidebar-sub-item:hover {
    color: #D23168;
    text-decoration: none;
}
.sidebar-lesson-block {
    background: #F4F4FC;
    width: 100%;
    text-align: center;
}
.sidebar-lesson-block--desctop {
    display: block;
}
.sidebar-lesson-block {
    background: #F4F4FC;
    box-sizing: border-box;
    padding: 12px 12px 10px 12px;
    margin-bottom: 32px;
}
.sidebar-lesson-block__title {
    color: #473F95;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    padding-bottom: 6px;
}
.sidebar-lesson-block__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #473F95;
    padding-bottom: 8px;
}
.sidebar-lesson-block__lesson {
    /* display: none; */
}
.sidebar-lesson-block__line {
    display: none;
}
.sidebar-lesson.btn-danger {
    padding: 13px 18px;
    transition: all 0.2s ease;
    font-size: 13px;
    line-height: 16px;
    width: 174px;
}
@media (max-width: 991px) {
  .Sidebar {
    display: none;
    left: 15px;
    position: absolute;
    top: 130px;
    z-index: auto;
  }
  .sidebar-item {
    padding-left: 38px;
  }
  .sidebar-sub-item-text {
    margin-left: 13px;
  }
  .sidebar-sub {
    padding-left: 46px;
  }
  .sidebar-lesson-block {
      display: block;
  }
  .sidebar-lesson-block__lesson {
      border-top: 1px solid #E6E6F2;
      display: block;
        padding: 12px 12px 0 12px;
        margin-left: -12px;
        margin-right: -12px;
  }
  .sidebar-lesson-block__line {
      display: block;
      border-bottom: 1px solid #E6E6F2;
        padding: 6px 12px 0 12px;
        margin-left: -12px;
        margin-right: -12px;
  }
  .sidebar-lesson-block--desktop {
      display: none;
  }
  .sidebar-lesson-block {
    padding: 12px 12px 10px 18px;
  }
}
</style>