import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import VuexRouterSync from 'vuex-router-sync'
import App from './App'
import JQuery from 'jquery';
import ElementUi from 'element-ui';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
import localeKz from 'element-ui/lib/locale/lang/kz'
import localeRu from 'element-ui/lib/locale/lang/ru-RU'
import VueCarousel from 'vue-carousel';
import moment from 'moment'
import VueKatex from 'vue-katex';
import '@/plugins'
import '@/validators'
import '@/directives'
import '@/locales'
import '@/components'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../public/css/fonts.css';
import '../public/css/main.css';
import '../public/css/media.css';

Vue.use(VueKatex, {
  globalOptions: {
    // ... Define globally applied KaTeX options here
  }
});

import Quill from 'quill'
import QuillResize from '@/components/quill-resize-module';
Quill.register('modules/resize', QuillResize);

VuexRouterSync.sync(store, router)

Vue.config.productionTip = false
Vue.prototype.moment = moment

let currentLocale = 'kz'
if (localStorage.getItem('locale') && localStorage.getItem('locale') !== '') {
  currentLocale = localStorage.getItem('locale')
}

window.$ = JQuery;
Vue.use(BootstrapVue)
if (currentLocale === 'kz') {
  Vue.use(ElementUi, {locale: localeKz})
} else {
  Vue.use(ElementUi, {locale: localeRu})
}

Vue.use(VueCarousel)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  ...App
})
