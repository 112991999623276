const SET_MENU_ID = "SET_MENU_ID"

const state = {
    activeMenu: 0,
    showSidebar: true,
}

const getters = {
    getActiveMenu: (state) => {
        return state.activeMenu
    }
}

const mutations = {
    [SET_MENU_ID] (state, payload) {
        state.activeMenu = payload
    }
}

const actions = {
    setActiveMenu({commit}, payload) {
        commit(SET_MENU_ID, payload)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
  }