import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Sidebar from '@/components/Sidebar'
import SidebarTeacher from '@/components/SidebarTeacher'
import ProfileHeader from "@/components/ProfileHeader"
import ProfileHeaderTeacher from "@/components/ProfileHeaderTeacher"
import QuizHeader from "@/views/Quiz/Navbar"
import Footer from '@/components/Footer'

export default [
  {
    path: '/',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/Index')
    }
  },
  // {
  //   path: '/for-demo',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Home/IndexForDemo')
  //   }
  // },
  {
    path: '/about',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/About') }
  },
  {
    path: '/for-members',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/ForMembers') }
  },
  {
    path: '/for-teachers',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/ForTeachers') }
  },
  {
    path: '/for-parents',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/ForParents') }
  },
  {
    path: '/faq',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/Faq') }
  },
  {
    path: '/moderation-policy',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/ModerationPolicy') }
  },
  {
    path: '/terms-of-use',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/TermsOfUse') }
  },
  {
    path: '/privacy-policy',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/PrivacyPolicy') }
  },
  {
    path: '/search-results',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/SearchResults') }
  },
  {
    path: '/teacher',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Home/Teacher') }
  },
  {
    path: '/signup',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Index') }
  },
  {
    path: '/forget-password',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Auth/ForgetPassword/Index') }
  },
  {
    path: '/reset-password',
    components: { header: Navbar, footer: Footer, default: () => import('@/views/Auth/ResetPassword') }
  },
  {
    name: 'student-profile',
    path: '/profile',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Main') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'conference',
    path: '/conference/:room',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Conference') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/schedule',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/ScheduleNew') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'lessons',
    path: '/diary/lessons',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Lessons') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'lesson-detail',
    path: '/diary/lesson/:id',
    components: { header: ProfileHeader, sidebar: Sidebar, default: () => import('@/views/Profile/Lesson') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'diary-homeworks',
    path: '/diary/homeworks',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/HomeWorks') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'homework-detail',
    path: '/diary/homework/:id',
    components: { header: ProfileHeader, sidebar: Sidebar, default: () => import('@/views/Profile/Homework') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'diary-ktp',
    path: '/diary/ktp',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Ktp') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'diary-ktp-details',
    path: '/diary/ktp/:id',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/KtpDetails') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'performance',
    path: '/diary/performance',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Performance') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/diary/attendance',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Attendance') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/diary/rating',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Rating') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/achievement/certificates',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Certificates') },
    // components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/In-progress') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/achievement/awards',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Awards') },
    // components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/In-progress') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'test',
    path: '/test',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Test') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/notifications',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Notifications') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/settings',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Settings') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    path: '/support',
    components: { header: ProfileHeader, sidebar: Sidebar, footer: Footer, default: () => import('@/views/Profile/Support') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'student' }
  },
  {
    name: 'quiz-enter',
    path: '/quiz/enter/:id/:lang',
    components: { header: QuizHeader, default: () => import('@/views/Quiz/Enter') },
    meta: { layout: 'Quiz', requiresAuth: true, role: 'student' }
  },
  {
    name: 'quiz-about',
    path: '/quiz/about',
    components: { header: QuizHeader, default: () => import('@/views/Quiz/About') },
    meta: { layout: 'Quiz', requiresAuth: true, role: 'student' }
  },
  {
    name: 'quiz-question',
    path: '/quiz/question/:id',
    components: { header: QuizHeader, default: () => import('@/views/Quiz/Test/Index') },
    meta: { layout: 'Quiz', requiresAuth: true, role: 'student' }
  },
  {
    name: 'result',
    path: '/result/:id',
    components: { header: QuizHeader, default: () => import('@/views/Profile/Result') },
    meta: { layout: 'Quiz', requiresAuth: true, role: 'student' }
  },
  {
    name: 'teacher-profile',
    path: '/profile-teacher',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Main') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    path: '/teacher-schedule',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/TeacherScheduleNew') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'ktp-index',
    path: '/journal/ktp',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Ktp') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'ktp-details',
    path: '/journal/ktp/:id',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/KtpDetails') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'test-results',
    path: '/journal/results',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Results') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'homeworks',
    path: '/journal/homeworks',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/HomeWorks') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'homework',
    path: '/journal/homework/:id',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/HomeWork') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    path: '/journal/performance',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Performance') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    path: '/journal/attendance',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Attendance') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'tests',
    path: '/tests',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Tests') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'test-update',
    path: '/test/:id',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Test') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'bank',
    path: '/bank',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/TestBank') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'question-update',
    path: '/question/:id',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Question') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    path: '/teacher/settings',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/PasswordUpdate') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'teacher-lesson',
    path: '/teacher/lesson/:id',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Lesson') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'teacher-conference',
    path: '/teacher/conference/:room',
    components: { header: ProfileHeaderTeacher, sidebar: SidebarTeacher, footer: Footer, default: () => import('@/views/Profile-Teacher/Conference') },
    meta: { layout: 'Profile', requiresAuth: true, role: 'teacher' }
  },
  {
    name: 'question-preview',
    path: '/quiz/question-preview/:id',
    components: { header: QuizHeader, default: () => import('@/views/Quiz/Test/PreviewQuestion') },
    meta: { layout: 'Quiz', requiresAuth: true, role: 'teacher' }
  },
  // {
  //   path: '/admin-authorization',
  //   components: { default: () => import('@/views/Home/AdminAuthorization') }
  // },
  {
    path: '*',
    components: {
      header: Navbar,
      footer: Footer,
      sidebar: Sidebar,
      quizHeader: QuizHeader,
      default: Empty
    }
  }
]
