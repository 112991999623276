<template>
  <component :is="layout"></component>
</template>

<script>
import Vue from 'vue';
import App from "./views/Layouts/App";
import Profile from "./views/Layouts/Profile";
import Quiz from "./views/Layouts/Quiz";
export default {
  name: 'App',
  components: {
    App,
    Profile,
    Quiz
  },
  data() {
    return {
      layout: null
    }
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = 'App'
      }
    }
  },
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
    Vue.http.headers.common['X-Locale'] = this.$i18n.locale;
  },
  mounted() {
    let script = document.createElement('script');
    switch (this.$i18n.locale) {
      case 'ru':
        script.src = 'https://code.jivosite.com/widget/RVLGonNe05';
        break;
      case 'kz':
        script.src = 'https://code.jivosite.com/widget/pq5dJrkw36';
        break;
    }
    script.async = true;
    document.body.appendChild(script);
  }
}
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  min-height: 100%;
  position: relative;
}
.router-view {
  transition: all .5s ease;
}
.router-enter, .router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>