<template>
    <div class="Sidebar">
        <div class="sidebar-logo">
            <a href="#" @click.prevent="$router.push({ path: '/' })">
                <img src="/images/logo.png" alt="">
            </a>
        </div>
        <div class="sidebar-lesson-block" 
             :class="{'d-none': !scheduleLinkIsExists(), 'd-block': scheduleLinkIsExists()}" target="_blank">
<!--            <div class="sidebar-lesson-block__lesson">-->
<!--                <div class="sidebar-lesson-block__title">-->
<!--                    Русский язык-->
<!--                </div>-->
<!--                <div class="sidebar-lesson-block__time">-->
<!--                    <img src="/images/profile/clock.svg" width="15" height="16" class="mr-1" alt="">-->
<!--                    <div>14:00-14:45</div>-->
<!--                </div>-->
<!--            </div>-->
            <el-button
                :loading="loading"
                @click.prevent="goToScheduleLink"
                :href="scheduleLink"
                class="sidebar-lesson m-2 mx-auto btn-danger">
                    {{ $t('profile.connect-to-lesson') }}
            </el-button>
        </div>
        <div class="sidebar-items">
            <div @click="achievementSubMenu = false; diarySubMenu = false">
                <router-link to="/profile" class="sidebar-item" active-class="active">
                    <img src="/images/profile-main-active.svg" class="sidebar-item__icon" v-if="$route.path === '/profile'" alt="">
                    <img src="/images/profile-main-1.svg" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.main-item') }}</p>
                </router-link>
                <router-link to="/schedule" class="sidebar-item" active-class="active">
                    <img src="/images/profile-schedule-2-active.svg" class="sidebar-item__icon" v-if="$route.path === '/schedule'" alt="">
                    <img src="/images/profile-schedule-2.svg" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.schedule-item') }}</p>
                </router-link>
            </div>
            <div class="sidebar-item" :class="{active: $route.path.substring(0, 6) === '/diary'}"
                 @click="achievementSubMenu = false; diarySubMenu=!diarySubMenu">
                <img src="/images/profile-dnevnik-active.svg" class="sidebar-item__icon" v-if="$route.path.substring(0, 6) === '/diary'" alt="">
                <img src="/images/profile-dnevnik.svg" class="sidebar-item__icon" v-else alt="">
                <p class="sidebar-item__title">{{ $t('profile.diary-item') }}</p>
                <img src="/images/profile-chevron-up.svg" class="sidebar-item__arrow" alt="">
                <img src="/images/profile-chevron-up-active.svg" class="sidebar-item__arrow-active" alt="">
            </div>
            <nav class="sub-sidebar" v-if="diarySubMenu">
                <ul class="sub-sidebar__list">
                    <router-link tag="li" to="/diary/lessons" :class="{'sub-sidebar__item--active': $route.name === 'lesson-detail'}" class="sub-sidebar__item" active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.lessons-sub-item') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/diary/homeworks" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.homework-sub-item') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/diary/ktp" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.ktp.title') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/diary/performance" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.performance-sub-item') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/diary/attendance" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.attendance-sub-item') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/diary/rating" class="sub-sidebar__item" active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.rating-sub-item') }}
                        </a>
                    </router-link>
                </ul>
            </nav>
            <div class="sidebar-item" :class="{active: $route.path.substring(0, 12) === '/achievement'}"
                 @click="diarySubMenu = false; achievementSubMenu=!achievementSubMenu">
                <img src="/images/profile-achievement-active.svg" class="sidebar-item__icon" v-if="$route.path.substring(0, 12) === '/achievement'" alt="">
                <img src="/images/profile-achievement.svg" class="sidebar-item__icon" v-else alt="">
                <p class="sidebar-item__title">{{ $t('profile.achievement-item') }}</p>
                <img src="/images/profile-chevron-up.svg" class="sidebar-item__arrow" alt="">
                <img src="/images/profile-chevron-up-active.svg" class="sidebar-item__arrow-active" alt="">
            </div>
            <nav class="sub-sidebar" v-if="achievementSubMenu">
                <ul class="sub-sidebar__list">
                    <router-link tag="li" to="/achievement/certificates" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.certificates-sub-item') }}
                        </a>
                    </router-link>
                    <router-link tag="li" to="/achievement/awards" class="sub-sidebar__item"
                                 active-class="sub-sidebar__item--active">
                        <a href="#" class="sub-sidebar__link">
                            {{ $t('profile.awards-sub-item') }}
                        </a>
                    </router-link>
                </ul>
            </nav>
            <div @click="achievementSubMenu = false; diarySubMenu = false">
                <router-link to="/test" class="sidebar-item" active-class="active">
                    <img src="/images/profile-test-active.svg" class="sidebar-item__icon" v-if="$route.path === '/test'" alt="">
                    <img src="/images/profile-test.svg" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.test-item') }}</p>
                </router-link>
                <router-link to="/notifications" class="sidebar-item" active-class="active">
                    <img src="/images/profile-notifications-active.svg" class="sidebar-item__icon" v-if="$route.path === '/notifications'" alt="">
                    <img src="/images/profile-notifications.svg" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.notifications-item') }}</p>
                </router-link>
                <router-link to="/settings" class="sidebar-item" active-class="active">
                    <img src="/images/settings-active.png" class="sidebar-item__icon" v-if="$route.path === '/settings'" alt="">
                    <img src="/images/settings.png" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.settings-item') }}</p>
                </router-link>
                <router-link to="/support" class="sidebar-item" active-class="active">
                    <img src="/images/profile-support-2-active.svg" class="sidebar-item__icon" v-if="$route.path === '/support'" alt="">
                    <img src="/images/profile-support-2.svg" class="sidebar-item__icon" v-else alt="">
                    <p class="sidebar-item__title">{{ $t('profile.support-item') }}</p>
                </router-link>
            </div>
        </div>
        <!--
        <div class="sidebar-lesson-block sidebar-lesson-block--desktop">
            <div class="sidebar-lesson-block__lesson">
                <div class="sidebar-lesson-block__title">
                    Русский язык
                </div>
                <div class="sidebar-lesson-block__time">
                    <img src="/images/profile/clock.svg" width="15" height="16" class="mr-1">
                    <div>14:00-14:45</div>
                </div>
            </div>
            <div class="sidebar-lesson-block__line"></div>
            <el-button
                :loading="loading"
                @click.prevent="goToScheduleLink"
                :href="scheduleLink"
                class="sidebar-lesson m-2 mx-auto btn-danger"
               :class="{'d-none': !scheduleLinkIsExists(), 'd-block': scheduleLinkIsExists()}" target="_blank">
                {{ $t('profile.connect-to-lesson') }}
            </el-button>
        </div>
        -->
        <!-- <div class="sidebar-item" :class="{active: activeMenu === 1}" @click="setMenu(1)">
            <img src="/images/profile-progress.svg">
            <p>{{$t('profile.progress')}}</p>
        </div>
        <div class="sidebar-item" :class="{active: activeMenu === 2}" @click="setMenu(2)">
            <img src="/images/profile-trophies.svg">
            <p>{{$t('profile.trophies')}}</p>
        </div>
        <div class="sidebar-item" :class="{active: activeMenu === 3}" @click="setMenu(3)">
            <img src="/images/profile-homework.svg">
            <p>{{$t('profile.homework')}}</p>
        </div>
        <div class="sidebar-item" :class="{active: activeMenu === 5}" @click="setMenu(5)">
            <img src="/images/profile-library.svg">
            <p>{{$t('profile.library')}}</p>
        </div>
        <div class="sidebar-item" :class="{active: activeMenu === 6}" @click="setMenu(6)">
            <img src="/images/profile-communication.svg">
            <p>{{$t('profile.communication')}}</p>
        </div>
        <div class="sidebar-item" :class="{active: activeMenu === 9}" @click="setMenu(9)">
            <img src="/images/profile-callback.svg">
            <p>{{$t('profile.callback')}}</p>
        </div> -->
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
    data() {
        return {
            loading: false,
            scheduleLink: null,
            diaryActiveItem: 'lessons',
            achievementActiveItem: 'certificates',
            diarySubMenu: false,
            achievementSubMenu: false,
        }
    },
    computed: {
        ...mapGetters({
            activeMenu: 'getActiveMenu',
        }),

    },
    methods: {
        async goToScheduleLink() {
            this.loading = true
            this.$http.get(window.API_ROOT + '/api/user/schedule-link')
                .then((res) => {
                    this.loading = false
                    sessionStorage.setItem('schedule-link', res.body.link)
                    this.scheduleLink = res.body.link
                    if (res.body.link != null) {
                        this.$router.push({ name: 'conference', params: { room: res.body.link } }).catch(err => {
                            console.log(err)
                        })
                        // window.open(res.body.link, '_blank');
                    } else {
                        Vue.toastr({
                            message: 'Урок не найден',
                            description: '',
                            type: 'danger'
                        })
                    }
                })
                .catch(() => {
                    this.loading = false
                });
        },
        getActualScheduleLink() {
            this.$http.get(window.API_ROOT + '/api/user/schedule-link')
                .then((res) => {
                    this.loading = false
                    sessionStorage.setItem('schedule-link', res.body.link)
                    this.scheduleLink = res.body.link
                })
                .catch(() => {
                    this.loading = false
                });
        },
        scheduleLinkIsExists () {
            return !(this.scheduleLink === 'null' || (typeof this.scheduleLink == 'object'));
        },
        makeActive(item) {
            this.activeSubMenu = item;
        },
        isActiveDiary(menuItem) {
            return this.diaryActiveItem === menuItem;
        },
        setActiveDiary(menuItem) {
            this.diaryActiveItem = menuItem;
        },
        isActiveAchievement(menuItem) {
            return this.achievementActiveItem === menuItem;
        },
        setActiveAchievement(menuItem) {
            this.achievementActiveItem = menuItem;
        },
    },
    mounted() {
        this.getActualScheduleLink()
    }
}
</script>

<style scoped>
.Sidebar {
    width: 204px;
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* border-radius: 0 12px 12px 0; */
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0 4px 16px rgb(0 0 0 / 8%);
    min-height: 100vh;
    /*overflow-y: scroll;*/
    top: 0;
    bottom: 0;
    /* padding-bottom: 70px; */
    font-family: 'Roboto', sans-serif;
    background: #FFF;
    z-index: 2;
}

::-webkit-scrollbar {
    display: none;
}

.sidebar-logo {
    padding: 28px 0 16px 0;
    width: 100%;
    /* border-radius: 0px 12px 12px 0px; */
    /* background: #F4F4FC; */
    background: #fff;
    display: flex;
    justify-content: center;
}
.sidebar-logo > a > img {
    width: 85px;
}
.sidebar-lesson-block {
    background: #F4F4FC;
    width: 100%;
    text-align: center;
}
.sidebar-lesson-block--desctop {
    display: block;
}
.sidebar-items {
    /* background-color: #fff; */
    width: 100%;
    border-radius: 0 12px 0 0;
    padding-bottom: 70px;
}
.sidebar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 11px 0 11px 24px;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    background-color: #FFF;
    text-decoration: none !important;
}

.sidebar-item:hover {
    background: #F4F4FC;
}

.sidebar-item.active {
    background: #473F95;
}

.sidebar-item p {
    font-weight: normal;
    font-size: 14px;
    line-height: 142%;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #473F95;
    margin: 0;
}

.sidebar-item.active p {
    color: #fff;
}

.sidebar-item__arrow,
.sidebar-item__arrow-active {
    position: absolute;
    right: 19px;
    bottom: 16px;
    width: 12px;
    height: 8px;
}

.sidebar-item .sidebar-item__arrow-active {
    display: none;
}

.sidebar-item.active .sidebar-item__arrow {
    display: none;
}

.sidebar-item.active .sidebar-item__arrow-active {
    display: block;
}
.sidebar-item__icon {
    width: 21px;
    height: 21px;
    margin-right: 16px;
}
.sidebar-lesson {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background: #D23168;
  border-radius: 5px;
  padding: 14px 15px;
  margin-bottom: 6px;
  border: none;
  min-width: 170px;
}
.sidebar-lesson.btn-danger {
    padding: 14px 18px;
    transition: all 0.2s ease;
}
button.el-button.sidebar-lesson.m-2.mx-auto.btn-danger.el-button--default.is-loading {
    padding: 14px 12px;
    transition: all 0.2s ease;
}

.sub-sidebar__list {
    margin-bottom: 0;
    margin-left: 31px;
    padding-left: 0;
}

.sub-sidebar__item {
    list-style: none;
    padding: 13px 0 13px 25px;
    border-left: 2px solid #D7CEEE;
}

.sub-sidebar__link {
    display: block;
    color: #473F95;
    letter-spacing: 0.005em;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
}

.sub-sidebar__link:hover {
    color: #D23168;
    text-decoration: none;
}

.sub-sidebar__item--active {
    padding: 13px 0 13px 23px;
    border-left: 4px solid #D23168;
}

.sub-sidebar__item--active > .sub-sidebar__link {
    color: #D23168;
    font-weight: 500;
}
.sidebar-lesson-block {
    background: #F4F4FC;
    box-sizing: border-box;
    padding: 12px 12px 10px 12px;
    margin-bottom: 32px;
}
.sidebar-lesson-block__title {
    color: #473F95;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    padding-bottom: 6px;
}
.sidebar-lesson-block__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #473F95;
    padding-bottom: 8px;
}
.sidebar-lesson-block__lesson {
    /* display: none; */
}
.sidebar-lesson-block__line {
    display: none;
}
.sidebar-item__title {
    margin-top: 0;
}
.sub-sidebar {
    /* background: #473F95; */
    background: #F7F4FF;
}
@media (max-width: 991px) {
  .Sidebar {
    display: none;
    left: 15px;
    position: absolute;
    top: 130px;
    z-index: auto;
  }
  .sidebar-lesson-block {
      display: block;
  }
  .sidebar-lesson-block__lesson {
      border-top: 1px solid #E6E6F2;
      display: block;
        padding: 12px 12px 0 12px;
        margin-left: -12px;
        margin-right: -12px;
  }
  .sidebar-lesson-block__line {
      display: block;
      border-bottom: 1px solid #E6E6F2;
        padding: 6px 12px 0 12px;
        margin-left: -12px;
        margin-right: -12px;
  }
  .sidebar-lesson-block--desktop {
      display: none;
  }
  .sidebar-lesson-block {
    padding: 12px 12px 10px 18px;
  }
  .sidebar-item {
    padding-left: 38px;
  }
  .sub-sidebar__list {
      margin-left: 46px;
  }
  .sub-sidebar__item {
      padding: 13px 0 13px 13px;
  }
}
</style>