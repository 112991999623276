<template>
  <div id="app" class="Profile_page app" ref="app">
    <router-view name="sidebar"></router-view>
    <div class="Profile_page_content">
      <router-view name="header"></router-view>
      <transition name="router" mode="out-in">
        <router-view class="view router-view"></router-view>
      </transition>
      <router-view name="footer"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileLayout',
}
</script>

<style>
.Profile_page {
  display: flex;
  justify-content: space-between;
}
.Profile_page_content {
  min-height: 100%;
  width: calc(100% - 204px);
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 35px 35px 35px;
  display: flex;
  flex-flow: column;
  background: #f3f3fb;
}
.Profile_page_content .footer {
    display: none!important;
}
@media (max-width: 991px) {
  .Profile_page_content {
    width: 100%;
    background-color: #fff;
    padding: 35px 16px 0px 16px;
  }
  .Profile_page_content .footer {
    display: block!important;
    margin-top: 60px;  
  }
}
</style>
