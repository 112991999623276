<template>
    <div>
        <div class="Profile-header-mob">
            <nav role="navigation">
                <div class="Profile-header-mob__menu profile-menu-mob">
                    <input type="checkbox" class="profile-menu-mob__input"  v-model="checked"/>
                    <span class="profile-menu-mob__line"></span>
                    <span class="profile-menu-mob__line"></span>
                    <span class="profile-menu-mob__line"></span>
                    
                    <div class="profile-menu-mob__list">
                        <SidebarTeacher />
                    </div>
                </div>
            </nav>
            <a href="#" class="Profile-header-mob__el-umiti">
                <img src="/images/logo_el_umiti.png" width="38" height="50" alt="">
            </a>
            <a href="#">
                <img src="/images/profile-header-logo.svg" width="51" height="42" alt="">
            </a>
            <img src="/images/profile-eye.svg" width="23" height="16" alt="">
        </div>
        <div class="Profile-header">
            <p class="Profile-header_title" v-if="$route.name === 'teacher-lesson'">{{$t('profile.schedule.lesson')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/teacher/lesson'">{{$t('profile.scheduleTeacher')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/profile-teacher'">{{$t('profile.main-item')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/teacher-schedule'">{{$t('profile.scheduleTeacher')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'ktp-index'">{{$t('profile.ktp.title')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'ktp-details'">{{$t('profile.ktp.title')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'homework'">{{$t('profile.homework-item')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'homeworks'">{{$t('profile.homework-item')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/journal/performance'">{{$t('profile.performance-sub-item')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/journal/attendance'">{{$t('profile.attendance-sub-item')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/tests'">{{$t('profile.tests')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'test-update'">{{$t('profile.tests')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'bank'">{{$t('profile.testing.bank-question')}}</p>
            <p class="Profile-header_title" v-if="$route.name === 'question-update'">{{$t('profile.testing.bank-question')}}</p>
            <p class="Profile-header_title" v-if="$route.path === '/teacher/settings'">{{$t('profile.settings.settings')}}</p>
            <div class="search">
                  <el-input
                    :placeholder="$t('profile.header.search')"
                    v-model="search"
                    >
                    <img slot="prefix" class="search__icon" src="/images/search-icon.svg" alt="">
                </el-input>
            </div>
            <div class="Profile-header_static">

                <img class="d-none" src="/images/profile-eye.svg" alt="">
                <!-- <p class="profile-lang" v-if="$i18n.locale === 'kz'" @click="switchLocale('ru')">RU</p>
                <p class="profile-lang" v-if="$i18n.locale === 'ru'" @click="switchLocale('kz')">KZ</p> -->

                <el-dropdown @command="switchLocale" trigger="click" class="nav-link dropdown-toggle change-locale-button">
                    <span class="el-dropdown-link">{{ $i18n.locale }}</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="kz">{{ $t('profile.header.kz') }}</el-dropdown-item>
                        <el-dropdown-item command="ru">{{ $t('profile.header.ru') }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <button class="header__question d-none">
                    <img src="/images/question-icon.svg" alt="">
                </button>
                <div class="profile-divider"/>
                <el-dropdown trigger="click" v-if="user.first_name">
                    <span class="el-dropdown-link header__user-block">
                        <div class="header__user">
                            <img width="32" height="32" :src="avatarImage" class="avatar rounded" alt="">
                            <div class="ml-2">
                                {{ user.last_name }} {{ user.first_name }}
                            </div>
                        </div>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <a @click.prevent="logoutMethod" class="profile-exit">{{ $t('navbar.signout') }}</a>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- <a @click.prevent="logoutMethod" class="profile-exit">{{ $t('navbar.signout') }}</a> -->
                <!-- <a href="#" @click.prevent="$router.push({ path: '/' })"><img src="/images/profile-header-logo.svg" alt=""></a> -->
            </div>
            <div class="profile-langs-mob">
                <p class="profile-lang" :class="{'lang-active': $i18n.locale === 'ru'}" @click="switchLocale('ru')">{{ $t('profile.header.ru') }}</p>
                <p class="profile-lang" :class="{'lang-active': $i18n.locale === 'kz'}" @click="switchLocale('kz')">{{ $t('profile.header.kz') }}</p>
            </div>
        </div>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'teacher-lesson'">{{$t('profile.schedule.lesson')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/teacher/lesson'">{{$t('profile.scheduleTeacher')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/profile-teacher'">{{$t('profile.main-item')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/teacher-schedule'">{{$t('profile.scheduleTeacher')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'ktp-index'">{{$t('profile.ktp.title')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'ktp-details'">{{$t('profile.ktp.title')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'homework'">{{$t('profile.homework-item')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'homeworks'">{{$t('profile.homework-item')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'test-results'">{{$t('profile.results')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/journal/performance'">{{$t('profile.performance-sub-item')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/journal/attendance'">{{$t('profile.attendance-sub-item')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/tests'">{{$t('profile.tests')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'test-update'">{{$t('profile.tests')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'bank'">{{$t('profile.testing.bank-question')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.name === 'question-update'">{{$t('profile.testing.bank-question')}}</p>
        <p class="Profile-header_title  Profile-header_title--desktop" v-if="$route.path === '/teacher/settings'">{{$t('profile.settings.settings')}}</p>
    </div>
</template>
<script>
import SidebarTeacher from '../components/SidebarTeacher';
export default {
    components: {
        SidebarTeacher
    },
    data() {
        return {
            search: '',
            user: [],
            avatarImage: '',
            checked: false
        }
    },
    watch: {
        '$route' (to, from) {
            this.checked = false;
        }
    },
    beforeMount() {
        this.logged = !(localStorage.getItem('token') === '');

        this.$http.get(window.API_ROOT + '/api/teacher/edit')
            .then((res) => {
                this.user = res.data.data
                this.avatar = this.user.avatar
                this.avatarImage = window.API_ROOT + '/api/user/avatar?file=' + this.avatar
                localStorage.setItem('user', JSON.stringify(this.user))
                sessionStorage.setItem('avatar-url', this.avatar)
            }).catch((e) => {
                if (e.status === 401 || e.status === 403){
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    this.$router.push('/')
                }
            });
    },
    methods: {
        switchLocale(key) {
            this.$i18n.locale = key;
            localStorage.setItem('locale', key);
            this.$router.go(this.$router.currentRoute)
        },
        logoutMethod() {
            localStorage.setItem('session_id', '');
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            window.location = '/';
        },
    }
}
</script>

<style lang="less" scoped>
    .Profile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        padding: 12px 35px;
        margin-left: -35px;
        margin-right: -35px;
        background: #FFFFFF;
        box-shadow: 0 0 13px rgba(97, 57, 147, 0.08);
    }
    .Profile-header p {
        margin: 0;
    }
    .Profile-header .Profile-header_title {
        color: #473F95;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.3px;
    }
    .Profile-header .Profile-header_static {
        display: flex;
        align-items: center;
    }
    .Profile-header .Profile-header_static .profile-lang {
        margin: 0 40px 0 20px;
        color: #473F95;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .Profile-header .Profile-header_static .profile-divider {
        margin-right: 26px;
        height: 42px;
        width: 1px;
        background: #E6EAED;
    }
    .Profile-header .Profile-header_static .profile-exit {
        color: #D23168;
        letter-spacing: 0.2px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        margin-right: 46px;
        cursor: pointer;
    }

    .Profile-header-mob {
        display: none;
    }
    .profile-menu-mob {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }
    .profile-menu-mob .profile-menu-mob__item {
        text-decoration: none;
        color: #232323;
        transition: color 0.3s ease;
    }
    .profile-menu-mob .profile-menu-mob__input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }
    .profile-menu-mob .profile-menu-mob__line {
        display: block;
        // width: 27px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #473F95;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
    }

    .profile-menu-mob .profile-menu-mob__line:nth-child(2) {
        transform-origin: 0 0;
        width: 27px;
    }
    .profile-menu-mob .profile-menu-mob__line:nth-child(3) {
        transform-origin: 0 100%;
        width: 20px;
    }
    .profile-menu-mob .profile-menu-mob__line:nth-child(4) {
        width: 13px;
    }
    .profile-menu-mob .profile-menu-mob__input:checked ~ .profile-menu-mob__line {
        opacity: 1;
    }
    .profile-menu-mob .profile-menu-mob__input:checked ~ .profile-menu-mob__line:nth-last-child(3) {
        opacity: 1;
    }
    .profile-menu-mob__list {
        position: absolute;
        width: 300px;
        margin: -100px 0 0 -50px;
        padding: 125px 50px;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0 0;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .profile-menu-mob__list .profile-menu-mob__item {
        padding: 10px 0;
        font-size: 22px;
    }
    .profile-menu-mob .profile-menu-mob__input:checked ~ .profile-menu-mob__list {
        transform: none;
    }
    .profile-langs-mob {
        display: none;
    }
    .profile-langs-mob .profile-lang {
        color: #473F95;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Manrope', Roboto, sans-serif;
        margin-left: 9px;
    }
    .profile-langs-mob .profile-lang.lang-active {
        color: #fff;
        background-color: #473F95;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search {
        width: 327px;
        background: #FFFFFF;
        box-shadow: 0 0 13px rgba(97, 57, 147, 0.12);
        border-radius: 8px;
        visibility: hidden;
    }
    .search__icon {
        margin-top: 8px;
        margin-left: 8px;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding-left: 56px;
        border: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    /deep/ .el-input__inner::placeholder {
        color: #473F95;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .change-locale-button {
        display: block;
        padding-left: 26px;
        padding-right: 26px;
        color: #473F95;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
    }
    .change-locale-button > span {
        padding-right: 12px;
    }
    .header__question {
        border: none;
        background: transparent;
        padding: 0;
        margin-right: 26px;
    }
    .header__user-block.el-dropdown-link {
        display: flex;
        align-items: center;
    }
    .header__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #473F95;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .el-icon-arrow-down {
        color: #473F95;
        font-size: 16px;
        font-weight: 700;
        margin-left: 16px;
    }
    /deep/ .el-dropdown-item {
        color: #473F95;
    }
    .Profile-header .Profile-header_title {
        display: none;
    }
    .Profile-header_title--desktop {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.3px;
        color: #473F95;
        margin-bottom: 26px;
    }
    @media (max-width: 991px) {
        .Profile-header .Profile-header_title {
            display: block;
        }
        .header__user-block.el-dropdown-link,
        .Profile-header_title--desktop {
            display: none;
        }
        .change-locale-button {
            display: none;
        }
        .Profile-header .Profile-header_static {
            display: none!important;
        }
        .Profile-header-mob {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #F5F4FC;
            box-shadow: 0 1px 0 #E5E9F2;
            margin-top: -35px;
            margin-left: -16px;
            margin-right: -16px;
            padding: 11px 25px;
            width: 100vw;
        }
        .profile-langs-mob {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .Profile-header .Profile-header_title {
            font-size: 14px;
            line-height: 16px;
            color: #D23168;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: normal;
        }
        .Profile-header {
            margin: 8px 0 36px 0;
        }
        .profile-menu-mob__list .Sidebar {
            display: block;
            top: 64px;
        }
        .Profile-header-mob__el-umiti {
            display: none;
        }
        .Profile-header {
            background: transparent;
            box-shadow: none;
        }
        .search {
            visibility: hidden;
        }
    }
</style>