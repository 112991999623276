<template>
  <div id="app" class="app" ref="app">
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
}
</script>
